import React from "react";
import { graphql } from "gatsby";
import {
    GutterWrapper,
    LeadGenerationButton,
    LearnerFeatures,
    PageSectionImageText,
    PageSectionVideoText,
    PageTemplateWrapper,
    ProductSampleText,
    QuoteAttribution
} from "@resourcehub/resourcehub-components";
import configGenerator from "@resourcehub/resourcehub-configuration";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";

const SectionWrapper = styled.div`
    margin-bottom: 100px;
`;

const Page = ({ data }) => {
    const {
        site,
        iphone_blue,
        tutor_screen,
        section_5_img_1,
        iphonex_24,
        iphonex_immersion,
        iphonex_engaging,
        iphonex_truaccent,
        iphonex_tutoring,
        iphonex_mobile,
        empty_iphone,
        bicicletaAzul,
        bicicletaAmarilla,
        dosBicicletasAzules,
        dosBicicletasDiferentes,
        verdesPorUnDia,
        azulesPorUnDia
    } = data;

    const config = configGenerator({
        market: site.siteMetadata.market,
        site: "education-primary",
        locale: site.siteMetadata.lang,
        page: "home"
    });

    const learnerFeatureImgs = {
        iphonex_24: {
            src: getImage(iphonex_24)
        },
        iphonex_immersion: {
            src: getImage(iphonex_immersion)
        },
        iphonex_engaging: {
            src: getImage(iphonex_engaging)
        },
        iphonex_truaccent: {
            src: getImage(iphonex_truaccent)
        },
        iphonex_tutoring: {
            src: getImage(iphonex_tutoring)
        },
        iphonex_mobile: {
            src: getImage(iphonex_mobile)
        },
        empty_iphone: {
            src: getImage(empty_iphone)
        }
    };

    config.page.content.sections[0].panels[0].children = (
        <>
            <LeadGenerationButton {...config.page.content.sections[0].panels[0].custom.lead_generation_button} />
        </>
    );

    config.page.content.sections[1].panels[0].children = (
        <QuoteAttribution {...config.page.content.sections[1].panels[0]} />
    );

    config.page.content.sections[2].panels[0].children = (
        <QuoteAttribution {...config.page.content.sections[2].panels[0]} />
    );

    config.page.content.sections[3].panels[0].children = (
        <QuoteAttribution {...config.page.content.sections[3].panels[0]} />
    );

    config.page.content.sections[4].panels[0].children = (
        <QuoteAttribution {...config.page.content.sections[4].panels[0]} />
    );

    config.page.content.sections[2].panels[0].image.src = getImage(iphone_blue);
    config.page.content.sections[3].panels[0].image.src = getImage(tutor_screen);
    config.page.content.sections[4].panels[0].image.src = getImage(section_5_img_1);
    config.page.content.sections[5].panels[0].data = { ...learnerFeatureImgs };

    config.page.content.sections[1].panels[0].panels[0].left_option.image.src = getImage(bicicletaAzul);
    config.page.content.sections[1].panels[0].panels[0].right_option.image.src = getImage(bicicletaAmarilla);

    config.page.content.sections[1].panels[0].panels[1].left_option.image.src = getImage(dosBicicletasAzules);
    config.page.content.sections[1].panels[0].panels[1].right_option.image.src = getImage(dosBicicletasDiferentes);

    config.page.content.sections[1].panels[0].panels[2].left_option.image.src = getImage(verdesPorUnDia);
    config.page.content.sections[1].panels[0].panels[2].right_option.image.src = getImage(azulesPorUnDia);

    return (
        <PageTemplateWrapper config={config}>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionVideoText {...config.page.content.sections[0]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <SectionWrapper>
                    <ProductSampleText {...config.page.content.sections[1].panels[0]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionImageText {...config.page.content.sections[2]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionImageText {...config.page.content.sections[3]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <SectionWrapper>
                    <PageSectionImageText {...config.page.content.sections[4]} />
                </SectionWrapper>
            </GutterWrapper>
            <GutterWrapper>
                <LearnerFeatures {...config.page.content.sections[5].panels[0]} />
            </GutterWrapper>
        </PageTemplateWrapper>
    );
};

export default Page;

export const pageQuery = graphql`
    {
        site {
            ...SiteFragment
        }
        section_1_img_1: file(relativePath: { eq: "images/hed-section-1-img.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1034, height: 586, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        iphone_blue: file(relativePath: { eq: "images/iphone-blue.png" }) {
            childImageSharp {
                gatsbyImageData(width: 938, height: 622, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        empty_iphone: file(relativePath: { eq: "images/empty-iphone.png" }) {
            childImageSharp {
                gatsbyImageData(width: 785, height: 450, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        tutor_screen: file(relativePath: { eq: "images/tutor-screen.png" }) {
            childImageSharp {
                gatsbyImageData(width: 778, height: 523, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        section_5_img_1: file(relativePath: { eq: "images/hed-section-5-img.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1068, height: 696, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        iphonex_24: file(relativePath: { eq: "images/iphonex-24.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, height: 462, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        iphonex_engaging: file(relativePath: { eq: "images/iphonex-engaging.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, height: 462, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        iphonex_immersion: file(relativePath: { eq: "images/iphonex-immersion.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, height: 462, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        iphonex_mobile: file(relativePath: { eq: "images/iphonex-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, height: 462, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        iphonex_truaccent: file(relativePath: { eq: "images/iphonex-truaccent.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, height: 462, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        iphonex_tutoring: file(relativePath: { eq: "images/iphonex-tutoring.png" }) {
            childImageSharp {
                gatsbyImageData(width: 1000, height: 462, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        screen_assessment: file(relativePath: { eq: "images/screen-assessment.png" }) {
            childImageSharp {
                gatsbyImageData(width: 704, height: 405, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        screen_implementation: file(relativePath: { eq: "images/screen-implementation.png" }) {
            childImageSharp {
                gatsbyImageData(width: 704, height: 405, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        screen_placement: file(relativePath: { eq: "images/screen-placement.png" }) {
            childImageSharp {
                gatsbyImageData(width: 704, height: 405, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        screen_reporting: file(relativePath: { eq: "images/screen-reporting.png" }) {
            childImageSharp {
                gatsbyImageData(width: 704, height: 405, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        screen_testing: file(relativePath: { eq: "images/screen-testing.png" }) {
            childImageSharp {
                gatsbyImageData(width: 704, height: 405, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        screen_training: file(relativePath: { eq: "images/screen-training.png" }) {
            childImageSharp {
                gatsbyImageData(width: 704, height: 405, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        azulesPorUnDia: file(relativePath: { eq: "product-sample/azulesPorUnDia.png" }) {
            childImageSharp {
                gatsbyImageData(width: 185, height: 185, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        bicicletaAmarilla: file(relativePath: { eq: "product-sample/bicicletaAmarilla.png" }) {
            childImageSharp {
                gatsbyImageData(width: 185, height: 185, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        bicicletaAzul: file(relativePath: { eq: "product-sample/bicicletaAzul.png" }) {
            childImageSharp {
                gatsbyImageData(width: 185, height: 185, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        dosBicicletasAzules: file(relativePath: { eq: "product-sample/dosBicicletasAzules.png" }) {
            childImageSharp {
                gatsbyImageData(width: 185, height: 185, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        dosBicicletasDiferentes: file(relativePath: { eq: "product-sample/dosBicicletasDiferentes.png" }) {
            childImageSharp {
                gatsbyImageData(width: 185, height: 185, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
        verdesPorUnDia: file(relativePath: { eq: "product-sample/verdesPorUnDia.png" }) {
            childImageSharp {
                gatsbyImageData(width: 185, height: 185, placeholder: TRACED_SVG, layout: CONSTRAINED)
            }
        }
    }
`;
